// --------------------------------------------------------- REACT ------
import * as React from 'react'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI --------
import {
    Box,
    IconButton
} from '@mui/material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI ICONS --
import {
    OpenInNew as OpenInNewIcon,
} from '@mui/icons-material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI OTHER --
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
// import { DataGridPremium, GridToolbar } from '@mui/x-data-grid-premium'
// ----------------------------------------------------------------------
// --------------------------------------------------------- SIMPLEUI ---
// import {    
//     SimpleUIAuthState,
// } from './../../simpleUI'
// ----------------------------------------------------------------------
// --------------------------------------------------------- OTHER ------
// ----------------------------------------------------------------------
// --------------------------------------------------------- COMPONENTS -
// ----------------------------------------------------------------------
// --------------------------------------------------------- LOCAL ------
// ----------------------------------------------------------------------
// --------------------------------------------------------- CONST ------
// ----------------------------------------------------------------------
export default (props) => {
    const { data } = props

    const handleOpenAsset = (invoiceId) => {
        window.open("https://cloudzolutions.syncromsp.com/customer_assets/"+ invoiceId, "SyncoMSP", "toolbar=0,Location=0")
    }

    const columnsAsset = [
        { field: 'asset_id', headerName: 'Id', minWidth: 100 },
        { field: 'type', headerName: 'Type', flex: 1 },
        { field: 'name', headerName: 'Navn', flex: 1 },
        { field: 'id', headerName: '', width: 50, sortable: false,
            renderCell: (params) => (         
                <IconButton variant={'contained'} size={"small"} fullWidth onClick={() => {handleOpenAsset(params)}}>
                    <OpenInNewIcon fontSize="inherit" color={"success"}/>
                </IconButton>
            ),
        },
    ]

    const [filterModel, setFilterModel] = React.useState({
        items: [],
        quickFilterExcludeHiddenColumns: true,
        quickFilterValues: [''],
      })

    return (
        <Box sx={{ height: '100%'}}>
            <DataGrid
                rows={data.assets}
                columns={columnsAsset}
                // slots={{ toolbar: GridToolbar }}
                // filterModel={filterModel}
                // onFilterModelChange={(newModel) => setFilterModel(newModel)}
                // slotProps={{ toolbar: { showQuickFilter: true } }}                                                        

                // rowGroupingColumnMode="multiple"

                initialState={{
                    sorting: {
                      sortModel: [{ field: 'type', sort: 'asc' }],
                    },
                    // rowGrouping: {
                    //     model: ["type"],
                    //   },
                }}                
            />
        </Box>
    )
}
