// --------------------------------------------------------- REACT ------
import * as React from 'react'
// import { useState, useEffect, useRef, useLayoutEffect } from 'react'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI --------
// import {
// } from '@mui/material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI ICONS --
// import {
// } from '@mui/icons-material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI OTHER --
// ----------------------------------------------------------------------
// --------------------------------------------------------- SIMPLEUI ---
// import {    
//     SimpleUIAuthState,
// } from './../../simpleUI'
// ----------------------------------------------------------------------
// --------------------------------------------------------- OTHER ------
// ----------------------------------------------------------------------
// --------------------------------------------------------- COMPONENTS -
// ----------------------------------------------------------------------
// --------------------------------------------------------- LOCAL ------
// ----------------------------------------------------------------------
// --------------------------------------------------------- CONST ------
// ----------------------------------------------------------------------

export default (props) => {
    const { data } = props

    return (
        <>
            {data.notes}
        </>
    )
}
