// --------------------------------------------------------- REACT ------
import * as React from 'react'
// import { useState, useEffect, useRef, useLayoutEffect } from 'react'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI --------
import {
    Typography,
    Grid,
} from '@mui/material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI ICONS --
// import {
// } from '@mui/icons-material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI OTHER --
// ----------------------------------------------------------------------
// --------------------------------------------------------- SIMPLEUI ---
import {    
    SimpleUIAuthState,
} from './../../../simpleUI'
// ----------------------------------------------------------------------
// --------------------------------------------------------- OTHER ------
// ----------------------------------------------------------------------
// --------------------------------------------------------- COMPONENTS -
// ----------------------------------------------------------------------
// --------------------------------------------------------- LOCAL ------
// ----------------------------------------------------------------------
// --------------------------------------------------------- CONST ------
// ----------------------------------------------------------------------

export default (props) => {
    const {data} = props
        
    return (
        <Grid container spacing={0.5}>
            <Grid item xs={3}>
                <Typography><b>Aftale:</b></Typography>
            </Grid>
            <Grid item xs={9}>
                <Typography>{data.contractName}</Typography>
            </Grid>
            <Grid item xs={3}>
                <Typography><b>Konsulent:</b></Typography>
            </Grid>
            <Grid item xs={9}>
                <Typography>{data.consultantName}</Typography>
            </Grid>
            <Grid item xs={3}>
                <Typography><b>Kunde:</b></Typography>
            </Grid>
            <Grid item xs={9}>
                <Typography>{data.customerName}</Typography>
            </Grid>            
            <Grid item xs={3}>
                <Typography><b>Navn:</b></Typography>
            </Grid>
            <Grid item xs={9}>
                <Typography>{data.contactName}</Typography>
            </Grid>
            <Grid item xs={3}>
                <Typography><b>Title:</b></Typography>
            </Grid>
            <Grid item xs={9}>
                <Typography>{data.contactDescription}</Typography>
            </Grid>
            <Grid item xs={3}>
                <Typography><b>E-mail:</b></Typography>
            </Grid>
            <Grid item xs={9}>
                <Typography>{data.contactEmail}</Typography>
            </Grid>
            <Grid item xs={3}>
                <Typography><b>Telefon:</b></Typography>
            </Grid>
            <Grid item xs={9}>
                <Typography>{data.contactPhone}</Typography>
            </Grid>                                        
        </Grid>
    )
}
