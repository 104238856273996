// --------------------------------------------------------- REACT ------
import React from "react";
import { useState, useEffect, useRef, useLayoutEffect } from 'react'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI --------
import {
    Box,
    Paper,
    Button,    
    Grid,
    CircularProgress,
    Collapse,
    Alert,
    Tabs
} from '@mui/material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI ICONS --
// import {
// } from '@mui/icons-material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI OTHER --
import Tab from '@mui/material/Tab'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
// ----------------------------------------------------------------------
// --------------------------------------------------------- SIMPLEUI ---
// import {    
//     SimpleUIAuthState,
// } from './../../simpleUI'
// ----------------------------------------------------------------------
// --------------------------------------------------------- OTHER ------
// ----------------------------------------------------------------------
// --------------------------------------------------------- COMPONENTS -
import Details from './componenets/details'
import Notes from './componenets/notes'
import Assets from './componenets/assets'
import Tickets from './componenets/tickets'
import Invoices from './componenets/invoices'
// ----------------------------------------------------------------------
// --------------------------------------------------------- LOCAL ------
// ----------------------------------------------------------------------
// --------------------------------------------------------- CONST ------
// ----------------------------------------------------------------------

export default (props) => {
    const queryParameters = new URLSearchParams(window.location.search)
    const queryString = queryParameters.get("query")    

    const appStateDefaults = {
        isInitializing: true,
        error: false,    
        errorText: "",        
    }

    const [appState, setAppState] = useState(appStateDefaults)
    const [data, setData] = useState({})

    const handleError = (error) => {        
        setAppState({ ...appState, error: true, errorText: error.message })
    }

    useEffect(() => {
        fetch(`/data/info?query=${queryString}`, {
            method: 'GET',
            headers: { 
                'Content-Type': 'application/json',
            }            
        })           
        .then(data => {                                                                
            return data.json ()
        })                
        .then(data => {                            
            if (data.error == null) {                
                setData(data)
                setAppState({...appState, isInitializing: false})

            } else {                              
                handleError(data.error)
            }
        })            
        .catch(error => {            
            handleError ({code: "ER_TELAVOXPOPUP_UNKNOWN_EXCEPTION", message: error.message})
        })        
    }, [])

    
    const [tab, setTab] = React.useState('1')
    const handleChange = (event, newValue) => {
        setTab(newValue);
    }

    const handleOpenCustomer = () => {
        window.open("https://cloudzolutions.syncromsp.com/customers/"+ data.customerId, "SyncoMSP", "toolbar=0,Location=0")
    }
      
    return (
        <>
            <Collapse in={appState.error}>
                <Alert 
                    variant="filled" 
                    severity="error"
                >
                {appState.errorText}
                </Alert>
            </Collapse>

            {(appState.isInitializing || appState.error) ? 
                <Box style={{ height: '100%', width: "100%", display: 'flex', alignItems: 'center', justifyContent: 'center',}}>
                    <CircularProgress style={{width: '50px', height: '50px'}}/>
                </Box>                  
            :
                <Paper sx={{p: "10px"}} style={{ height: '100vh', width: "100%",}}>
                    <Grid container spacing={1} sx={{height: '100%'}}>
                        <Grid item xs={7}>                            
                            <Box sx={{display: 'flex', flexDirection: 'column', height: '100%'}}>
                                <Box sx={{flexShrink: 0, p: 1, border: 1, borderRadius: '2px', borderColor: "rgba(0, 0, 0, 0.23)"}}>
                                    <Details data={data}></Details>
                                </Box>
                                <Box sx={{display: 'flex', flexDirection: 'column', height: '100%', mt: 1, p: 1, border: 1, borderRadius: '2px', borderColor: "rgba(0, 0, 0, 0.23)"}}>                                 
                                    <TabContext value={tab}>
                                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                            <TabList onChange={handleChange} 
                                                sx={{
                                                    ".Mui-selected": {
                                                        color: `#2e7d32 !important` ,
                                                        },

                                                }}
                                            >
                                                <Tab label={"Faktura ("+ data.invoices.length +")"} value="1" sx={{fontWeight: (data.invoices.length > 0 ? "bold" : "normal")}}/>
                                                <Tab label={"Tickets ("+ data.tickets.length +")"} value="2" sx={{fontWeight: (data.tickets.length > 0 ? "bold" : "normal")}}/>
                                                <Tab label={"Enheder & Tjenester ("+ data.assets.length +")"} value="3" sx={{fontWeight: (data.assets.length > 0 ? "bold" : "normal")}}/>
                                            </TabList>
                                        </Box>
                                        <Box style={{height:'100%'}}>
                                            <TabPanel value="1" sx={{m: 0, p: 0, height: 'calc(100%)'}}>
                                                <Invoices data={data}></Invoices>
                                            </TabPanel>
                                            <TabPanel value="2" sx={{m: 0, p: 0, height: '100%'}}>
                                                <Tickets data={data}></Tickets>
                                            </TabPanel>
                                            <TabPanel value="3" sx={{m: 0, p: 0, height: 'calc(100%)'}}>
                                                <Assets data={data}></Assets>
                                            </TabPanel>
                                        </Box>
                                    </TabContext>                                                            
                                </Box>
                            </Box>                            
                        </Grid>
                        <Grid item xs={5}>
                            <Box sx={{display: 'flex', flexDirection: 'column', height: '100%'  }}>
                                <Box sx={{height: '100%', p: 1, border: 1, borderRadius: '2px', borderColor: "rgba(0, 0, 0, 0.23)"}}> 
                                    <Notes data={data}></Notes>
                                </Box>                    
                                <Box sx={{mt: 1, flexShrink: 0}}>
                                    <Button variant={"contained"} size={"small"} fullWidth color={"success"} onClick={handleOpenCustomer}>Åben kunde i Syncro</Button>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Paper>
            }
        </>
    )
}
