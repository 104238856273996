import React from 'react'
import ReactDOM from 'react-dom'

import './simpleUI/index.css'

import {
    SimpleUIApp,
} from './simpleUI'

import Info from "./pages/info"

const settings = {
    useAuthentication: false,
    useEmailAsUsername: false,
    hideAppBar: true,
    hideNavBar: true
}

const pages = [
    {
        path: "/",
        Main: Info,        
    },    
]

ReactDOM.render(
    <React.StrictMode>
        <SimpleUIApp pages={pages} settings={settings}/>
    </React.StrictMode>,
    document.getElementById('root')
)