// --------------------------------------------------------- REACT ------
import * as React from 'react'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI --------
import {
    Box,
    IconButton,
} from '@mui/material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI ICONS --
import {
    OpenInNew as OpenInNewIcon,
} from '@mui/icons-material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI OTHER --
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
// import { DataGridPremium as DataGrid, GridToolbar } from '@mui/x-data-grid-premium'
// ----------------------------------------------------------------------
// --------------------------------------------------------- SIMPLEUI ---
// import {    
//     SimpleUIAuthState,
// } from './../../simpleUI'
// ----------------------------------------------------------------------
// --------------------------------------------------------- OTHER ------
// ----------------------------------------------------------------------
// --------------------------------------------------------- COMPONENTS -
// ----------------------------------------------------------------------
// --------------------------------------------------------- LOCAL ------
// ----------------------------------------------------------------------
// --------------------------------------------------------- CONST ------
// ----------------------------------------------------------------------
export default (props) => {
    const { data } = props

    const handleOpenInvoice = (invoiceId) => {
        window.open("https://cloudzolutions.syncromsp.com/invoices/"+ invoiceId, "SyncoMSP", "toolbar=0,Location=0")
    }

    const columnsAsset = [
        { field: 'number', headerName: 'Id', minWidth: 100 },
        { field: 'date', headerName: 'Dato', minWidth: 130 },
        { field: 'description', headerName: 'Beskrivelse', flex: 1 },
        { field: 'total', headerName: 'Total', flex: 0, headerAlign: 'right', align: 'right' },
        { field: 'id', headerName: '', width: 50, sortable: false,
            renderCell: (params) => (         
                <IconButton variant={'contained'} size={"small"} fullWidth onClick={() => {handleOpenInvoice(params)}}>
                    <OpenInNewIcon fontSize="inherit" color={"success"} />
                </IconButton>
            ),
        },
    ]

    const [filterModel, setFilterModel] = React.useState({
        items: [],
        quickFilterExcludeHiddenColumns: true,
        quickFilterValues: [''],
    })

    return (
        <Box sx={{ height: '100%'}}>
            <DataGrid
                rows={data.invoices}
                columns={columnsAsset}
                // slots={{ toolbar: GridToolbar }}
                // filterModel={filterModel}
                // onFilterModelChange={(newModel) => setFilterModel(newModel)}
                // slotProps={{ toolbar: { showQuickFilter: true } }}      
                initialState={{
                    sorting: {
                      sortModel: [{ field: 'number', sort: 'desc' }],
                    },
                }}                                                  
            />
        </Box>
    )
}
